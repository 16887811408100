<template>
    <div class="l-default l-auth t-login">
        <Sprite />
        <svg
            class="l-default__background"
            viewBox="0 0 1920 682"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            preserveAspectRatio="none"
        >
            <path
                d="M-12 -17H1933V573.652C1424.28 764.929 996.99 568.64 635.162 568.64C379.98 568.64 191.077 588.689 -12 681V-17Z"
                fill="#F3F6FF"
                stroke="#E2E6F8"
                stroke-linecap="round"
            />
        </svg>

        <main>
            <div class="l-auth__container">
                <a href="https://semjuice.com" class="a-button -transparent -small l-default__back text-nl-primary no-underline-hover">
                    <svg class="a-svg" aria-hidden="true" focusable="false">
                        <use xlink:href="#icon-chevron-left"></use>
                    </svg>
                    <span>Retourner sur le site de Semjuice</span>
                </a>
                <div class="o-panel">
                    <aside class="o-panel__aside bg-nl-primary text-nl-white">
                        <div>
                            <svg class="a-svg -logo" aria-hidden="true" focusable="false">
                                <use xlink:href="#icon-nl-logo"></use>
                            </svg>
                            <h1 class="text-l o-panel__aside--title">La plateforme d’achat de liens positionnés</h1>
                        </div>
                        <div class="m-card -transparent" data-mobile="l-auth__container">
                            <p class="text-p">
                                Vous préférez être accompagné.e de façon personnalisée par nos experts SEO ?
                            </p>
                            <a href="#" class="a-button -link color-white-hover">
                                <svg class="a-svg -big" aria-hidden="true" focusable="false">
                                    <use xlink:href="#icon-calendar"></use>
                                </svg>
                                <span>Prendre rendez-vous</span>
                                <svg class="a-svg" aria-hidden="true" focusable="false">
                                    <use xlink:href="#icon-chevron-right"></use>
                                </svg>
                            </a>
                            <img src="@/assets/img-v2/rdv.svg" alt="" />
                        </div>
                    </aside>

                    <section class="o-panel__main">
                        <h2 class="text-l o-panel__main--title">Bonjour ! 👋</h2>
                        <p class="text-pm text-nl-neutral-6">
                            Vous n’avez pas encore de compte ?
                            <router-link to="/register" class="a-link no-underline-hover"> Inscrivez-vous !</router-link>
                        </p>
                        <form action="" class="o-panel__form" method="POST">
                            <fieldset class="m-form__fieldset">
                                <div class="m-form__fields">
                                    <div>
                                        <label for="email" class="text-label"
                                            >E-mail <span class="required">*</span
                                        ></label>
                                        <input
                                            v-model="email"
                                            id="email"
                                            class="a-input -email"
                                            name="username"
                                            placeholder="Indiquez votre adresse e-mail"
                                            type="email"
                                        />
                                    </div>
                                    <div>
                                        <label for="password" class="text-label"
                                            >Mot de passe <span class="required">*</span
                                        ></label>
                                        <input
                                            v-model="password"
                                            id="password"
                                            class="a-input -password"
                                            name="password"
                                            placeholder="Indiquez votre mot de passe"
                                            type="password"
                                        />
                                        <div class="a-inputComment">
                                            <router-link to="/reset" class="a-link no-underline-hover">
                                                Mot de passe oublié ?
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <div class="m-form__footer">
                                <button class="a-button -primary" @click.prevent="doLogin()">
                                    <span>Me connecter sur NextLevel</span>
                                    <svg class="a-svg arrow" aria-hidden="true" focusable="false">
                                        <use xlink:href="#icon-arrow-right"></use>
                                    </svg>
                                </button>
                            </div>
                        </form>
                    </section>
                </div>
                <img class="l-auth__illu" src="@/assets/img-v2/nl-icon.svg" alt="" width="235" />
            </div>
        </main>
    </div>

</template>

<script>
    import Sprite from '@/views/Svg/Sprite.vue'
    import { mapActions } from 'vuex'

    export default {
        name: 'Login',
        components: { Sprite },
        title: 'register.login',
        data: function () {
            return {
                message: '',
                email: null,
                password: null
            }
        },
        i18n: {
            messages: {
                en: {
                    message: {
                        error: 'Invalid email or password'
                    }
                },
                fr: {
                    message: {
                        error: 'Email ou mot de passe invalid'
                    }
                }
            }
        },
        methods: {
            ...mapActions('auth', ['login']),
            doLogin() {
                this.login({ email: this.email, password: this.password })
                    .then(() => {
                        this.$router.push(this.$store.state.homepage)
                    })
                    .catch(error => {
                        this.$store.dispatch('toastError', {
                            data: {
                                message: error.response.data.message
                            },
                            status: error.response.status
                        })
                    })
            },
            moveCard() {
                const card = document.querySelector('[data-mobile]');
                const newParent = document.querySelector('.' + card?.dataset.mobile);
                const oldParent = document.querySelector('.o-panel__aside');

                if (!card || !newParent || !oldParent) return;

                if (window.innerWidth < 960) {
                    card.classList.add('-mobile');
                    newParent.appendChild(card);
                } else {
                    if (card.parentElement !== oldParent) {
                        card.classList.remove('-mobile');
                        oldParent.appendChild(card);
                    }
                }
            }
        },
        mounted() {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = "/css/app.css";
            link.id = 'customCss';
            document.head.appendChild(link);

            this.moveCard();
            window.addEventListener('resize', this.moveCard);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.moveCard);
        }
    }
</script>
